import React from "react";
import DashboardContainer from "./DashboardContainer";
import { TextField } from "@mui/material";
import Img from "../Images/man.png";

const EditProduct = () => {
  return (
     <DashboardContainer title={"BAC Store Details"}>
      <div className="mt-5">
        <form className="w-100">
          <div className="bac-add-product-grid">
            <div className="">
              <TextField
                id="outlined-basic"
                fullWidth
                label="Name"
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                fullWidth
                label="Location"
                variant="outlined"
              />
            </div>
            <div className="">
              <TextField
                id="outlined-basic"
                fullWidth
                label="Designation"
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                fullWidth
                label="Location"
                variant="outlined"
              />
            </div>
          </div>
          <div className="d-flex flex-wrap flex-row align-items-center gap-4 mt-5">
            <div
              style={{
                width: "160px",
                overflow: "hidden",
                borderRadius: "5px",
                height: "120px",
              }}
            >
              <img
                alt=""
                src={Img}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div className="d-flex flex-row align-items-center gap-3">
              <button className="dash-button dash-button-rounded px-3 bg-steelblue">
                Upload New
              </button>
              <button className="dash-button dash-button-rounded px-3">
                Delete
              </button>
            </div>
          </div>

          <button className="mt-4 align-self-start px-4 dash-button dash-button-rounded bg-steelblue">
            Submit
          </button>
        </form>
      </div>
    </DashboardContainer>
  );
};

export default EditProduct;
