import React, { useState } from "react";
import axios from "axios";

function Section3() {
  const [Input, setInput] = useState({
    ApplyingFor: "",
    FullName: "",
  });
  const [CVFile, setCVFile] = useState();
  const [IsLoading, setIsLoading] = useState(false);

  let getInput = (key, Value) => {
    setInput({ ...Input, [key]: Value });
  };

  let Submit = async (e) => {
    e.preventDefault();
    try {
      let Form = new FormData();
      Form.append("file", CVFile);
      Form.append("fullname", Input.FullName);
      Form.append("applyingFor", Input.ApplyingFor);

      if (!Input || !Input.ApplyingFor || !Input.FullName || !CVFile) {
        throw new Error("All fields are required.");
      }
      setIsLoading(true);

      await axios.post(
        "https://bacr-backend-production-08f3.up.railway.app/api/send-email",
        Form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setIsLoading(false);
      alert("Application has been send successfuly.");
      setInput({
        FullName: "",
        ApplyingFor: "",
      });
      setCVFile(null);
    } catch (error) {
      setIsLoading(false);
      alert(error?.message ?? error?.response.data.msg);
    }
  };

  return (
    <>
      <div className="container my-5 ">
        <div className="row my-5">
          <div className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mt-lg-5">
            <div className="d-flex align-items-start flex-column">
              <h1 className="text-start fw-bolder fs-3 display-6">Apply Now</h1>
              <p className="fs-6 display-6">
                To apply, please submit your resume and cover letter, clearly
                stating the position you are applying for in the subject line.
                We're thrilled to invite you to join the BAC family!
              </p>
            </div>
          </div>
          <form
            onSubmit={Submit}
            style={{ overflow: "hidden" }}
            className="col-lg-8 col-sm-12 col-md-12 d-flex justify-content-center align-items-center form-R-main mt-lg-5"
          >
            <div className=" from-inner d-flex justify-content-center align-items-center flex-column w-100 m-4 warning">
              <div className="title-inputs gap-2 w-100 d-flex justify-content-evenly align-items-center">
                <input
                  value={Input.FullName}
                  onChange={(e) => {
                    getInput("FullName", e.target.value);
                  }}
                  placeholder="Full Name"
                  className="w-50 "
                />
                <input
                  value={Input.ApplyingFor}
                  onChange={(e) => {
                    getInput("ApplyingFor", e.target.value);
                  }}
                  placeholder="Applying For"
                  className="w-50 "
                />
              </div>
              <div className="file-input d-flex justify-content-center">
                <input
                  id="file-upload"
                  onChange={(e) => {
                    setCVFile(e.target.files[0]);
                    if (!CVFile) {
                      e.target.value = null;
                    }
                  }}
                  className="w-100"
                  type="file"
                  data-file-name=""
                />
                <label htmlFor="file-upload" style={{ color: "#007bff" }}>
                  {CVFile ? CVFile.name : "Upload Cv"}
                </label>
              </div>

              <div className="buttons-dov d-flex align-items-center gap-2">
                {IsLoading ? (
                  <div className="spinner-border text-primary" />
                ) : (
                  <button type="submit" className="btn" onClick={Submit}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Section3;
