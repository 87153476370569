import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../../App";
import Expertise from "../ExpertiseSec/Expertise";
import Project from "../ProjectSec/Project";
import Contact from "../ContactSec/Contact";
import ExpertiseInner from "../ExpertiseInner/ExpertiseInner";
import AboutSec from "../AboutSec/AboutSec";
import OperationInr from "../OperationInner/OperationInr";
import Consultancy from "../Consultancy/index.js";
import StellinnerMaib from "../Stell_Struc_inner/StellinnerMaib";
import ZiaProject from "../ProjectSec/Project_Inner/ZiaProject";
import NationPorject from "../ProjectSec/NationPorject";
import OverseasProjet from "../ProjectSec/OverseasProjet.js";
import ClientProject from "../ProjectSec/ClientProject";
import DevisPLANT from "../ProjectSec/Project_Inner/DevisPLANT.js";
import Pso from "../ProjectSec/Project_Inner/Pso.js";
import Tufail from "../ProjectSec/Project_Inner/Tufai.js";
import Pharmna from "../../components/ProjectSec/Project_Inner/UgraitPharma.js";
import UgraitPharma from "../ProjectSec/Project_Inner/UgraitPharma.js";
import UnionPaper from "../ProjectSec/Project_Inner/UnionPaper.js";
import Kabul from "../ProjectSec/Project_Inner/Kabul.js";
import Zarghun from "../ProjectSec/Project_Inner/Zarghun.js";
import Dasboard from "../../Admin/Dasboard/index.js";
import Login from "../../Admin/Login";
import Brands from "../Brands/index.js";
import BrandDetails from "../BrandDetails/index.js";
import BACStore from "../BacStore/index.js";
import ComfortCooling from "../ComfortCooling/index.js";
import ColdChainSolutions from "../ColdChainSolutions/index.js";
import AnnualMaintenance from "../AnnualMaintenance/index.js";
import SparePartsProcurement from "../SparePartsProcurement/index.js";
import TrunkySolutions from "../TrunkySolutions/index.js";
import ProcessCoolingSolution from "../ProcessCoolingSolution/index.js";
import TroubleshootingAndConfig from "../Troubleshooting&Config/index.js";
import NewAndUsedChillers from "../New&UsedChillers/index.js";
import RouteShell from "../../Admin/Components/RouteShell.js";
import ForgetPassword from "../../Admin/ForgetPassword/index.js";


const routerSetup = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/Home",
    element: <App />,
  },
  {
    path: "/About",
    element: <AboutSec />,
  },
  {
    path: "/Brands",
    element: <Brands />,
  },
  {
    path: "/Brand/:id",
    element: <BrandDetails />,
  },
  {
    path: "/BAC_Store",
    element: <BACStore />,
  },
  {
    path: "/Expertise",
    element: <Expertise />,
  },
  {
    path: "/Projects",
    element: <Project />,
  },
  {
    path: "/Contact",
    element: <Contact />,
  },
  {
    path: "/procees-cooling-solution",
    element: <ExpertiseInner />,
  },
  {
    path: "/operation-maintenance",
    element: <OperationInr />,
  },
  {
    path: "/troubleshooting-&-config",
    element: <TroubleshootingAndConfig />,
  },
  {
    path: "/new-used-chillers",
    element: <NewAndUsedChillers />,
  },
  {
    path: "/consultancy",
    element: <Consultancy />,
  },
  {
    path: "/comfort-cooling",
    element: <ComfortCooling />,
  },
  {
    path: "/cold-chain-solution",
    element: <ColdChainSolutions />,
  },
  {
    path: "/annual-maintenance",
    element: <AnnualMaintenance />,
  },
  {
    path: "/spare-parts-procurement",
    element: <SparePartsProcurement />,
  },
  {
    path: "/trunky-solutions",
    element: <TrunkySolutions />,
  },
  {
    path: "/process-cooling-solutions",
    element: <ProcessCoolingSolution />,
  },
  {
    path: "/stell-structure",
    element: <StellinnerMaib />,
  },
  {
    path: "/Zia_Mohd_project",
    element: <ZiaProject />,
  },

  // PROJECTS
  {
    path: "/Projects/NationWide",
    element: <NationPorject />,
  },
  {
    path: "/Projects/OverseasePorject",
    element: <OverseasProjet />,
  },
  {
    path: "/Projects/ClientProject",
    element: <ClientProject />,
  },
  {
    path: "/Projects/DevisPlant",
    element: <DevisPLANT />,
  },
  {
    path: "/Projects/PSO",
    element: <Pso />,
  },
  {
    path: "/Projects/NationWide/TufailCehmiacls",
    element: <Tufail />,
  },
  {
    path: "/Projects/NationWide/Zia Mohyeddin Theatre",
    element: <ZiaProject />,
  },
  {
    path: "/Projects/Oversies/UgraitPharma",
    element: <Pharmna />,
  },
  {
    path: "/Projects/Oversies/Ugarit",
    element: <UgraitPharma />,
  },
  {
    path: "/Projects/Oversies/UnionPaper",

    element: <UnionPaper />,
  },
  {
    path: "/Projects/Oversies/Kabul",

    element: <Kabul />,
  },
  {
    path: "/Projects/Oversies/Zarghun",
    element: <Zarghun />,
  },
  {
    path: "/dashboard/*",
    element: <RouteShell Screen={Dasboard} />,
  },
  {
    path: "/Login",
    element: <Login />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
]);
export default routerSetup;
