import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css";
import { Autoplay } from "swiper/modules";

import Machine1 from "../HomeImages/cards/1.png";
import Machine2 from "../HomeImages/cards/2.png";
import Machine3 from "../HomeImages/cards/3.png";
import Machine4 from "../HomeImages/cards/4.png";
import Machine5 from "../HomeImages/cards/5.png";
import Machine6 from "../HomeImages/cards/6.png";
import Machine7 from "../HomeImages/cards/7.png";
import Machine8 from "../HomeImages/cards/8.png";
import Machine9 from "../HomeImages/cards/9.png";
import Machine10 from "../HomeImages/cards/10.png";
import Machine11 from "../HomeImages/cards/11.png";

// import Machine12 from "../HomeImages/cards/12.jpg";
// import Machine13 from "../HomeImages/cards/13.jpeg";
// import Machine14 from "../HomeImages/cards/14.png";

let machines = [
  Machine1,
  Machine2,
  Machine3,
  Machine4,
  Machine5,
  Machine6,
  Machine7,
  Machine8,
  Machine9,
  Machine10,
  Machine11,
  // Machine12,
  // Machine13,
  // Machine14,
];

const MachinesCarousel = ({ parentClass }) => {
  return (
    <div className={`${parentClass} container`}>
      <Swiper
        spaceBetween={-100}
        slidesPerView={3}
        centeredSlides
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 110,
          },
          "@0.75": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@1.00": {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          "@1.25": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@1.50": {
            slidesPerView: 3,
            spaceBetween: -50,
          },
        }}
        // grabCursor
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        speed={700}
        modules={[Autoplay]}
      >
        {machines?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="_hero_machine">
                <img alt="" src={item} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default MachinesCarousel;