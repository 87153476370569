import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import NewBrandForm from "../BrandsComponent/NewBrandForm";
import EditChildForm from "../BrandsComponent/EditChildForm";
import { DeleteRequest, DeleteRequestTest } from "../Actions/Api_Requests";
import { useDispatch } from "react-redux";
import {
  deleteChildCard,
  deleteParentCard,
} from "../../Redux/reducers/DataSlice";
import { NavLink } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableHeaders = [
  "Image",
  "Name",
  "Email",
  "Location",
  "Added Date",
  "Options",
];

export default function BrandsTable({ data }) {
  const [open, setOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();

  const handleDeleteCard = async (id) => {
    try {
      setActiveIndex(id);
      setIsLoading(true);
      await DeleteRequestTest(`/api/card/delete/parent/${id}`);
      dispatch(deleteParentCard(id));
      setIsLoading(false);
      setActiveIndex(null);
    } catch (error) {
      setActiveIndex(null);
      setIsLoading(false);
      alert(error.message);
    }
  };

  const handleDeleteChildCard = async (childId, parentId) => {
    try {
      setIsLoading(true);
      setActiveIndex(childId);
      await DeleteRequest(`/api/card/delete/${parentId}/${childId}`);
      dispatch(deleteChildCard({ parent: parentId, child: childId }));
      setIsLoading(false);
      dispatch(deleteChildCard({ parent: parentId, child: childId }));

    } catch (error) {
      setIsLoading(false);
      alert(error?.message);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className="">
          <TableRow className="p-3">
            {TableHeaders.map((item, index) => (
              <StyledTableCell
                key={index}
                className="bg-steelblue py-4"
                align="center"
              >
                <span>{item}</span>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, key) => (
            <React.Fragment key={key}>
              <StyledTableRow className="bg-white" key={row?.name}>
                <StyledTableCell align="center">
                  <div className="bard-logo-image-container">
                    <img alt="" className="" src={row?.image?.path} />
                  </div>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      setOpen(!open);
                      setActiveIndex(key);
                    }}
                  >
                    {open && activeIndex === key ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">{row?.name}</StyledTableCell>
                <StyledTableCell align="center">{row?.email}</StyledTableCell>
                <StyledTableCell align="center">
                  {row?.location}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {timeAgo(row?.createdAt ?? "")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="d-flex flex-row align-items-center gap-2 justify-content-center">
                    <NavLink
                      to={`/dashboard/edit-card/${row?._id}`}
                      className="btn"
                    >
                      <EditSvg/>                     
                    </NavLink>
                    <button
                      className="btn"
                      onClick={() => {
                        handleDeleteCard(row?._id);
                      }}
                    >
                       {isLoading 
                      && row?._id === activeIndex 
                      ? (
                       <LoadingIndicator/>
                      ) : (
                        <DeleteSvg />
                      )}
                    </button>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse
                    in={open && activeIndex === key}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {[0, 0, 0, 0, 0, 0, 0, 0].map((item, index) => (
                              <TableCell key={index}></TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row?.items?.map((child, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                <div className="bard-logo-image-container">
                                  <img
                                    alt=""
                                    className=""
                                    src={child?.image?.path}
                                  />
                                </div>
                              </TableCell>
                              <TableCell align="left">{child?.title}</TableCell>

                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right"></TableCell>

                              <TableCell align="right" className="">
                                {timeAgo(child?.createdAt ?? "")}
                              </TableCell>

                              <TableCell align="right">
                                <div className="d-flex flex-row align-items-center justify-content-end gap-2">
                                 <EditChildForm parentId={row?._id} childId={child?._id}/>
                                  <button
                                    className="btn"
                                    onClick={() => {
                                      handleDeleteChildCard(child?._id, row?._id);
                                    }}
                                  >
                                    {isLoading &&
                                      child?._id === activeIndex ? <LoadingIndicator/> : (
                                        <DeleteSvg />
                                      )}
                                  </button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <div className="d-flex flex-row justify-content-end my-2">
                        <NewBrandForm parentId={row?._id} />
                      </div>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const DeleteSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-trash3"
      viewBox="0 0 16 16"
    >
      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
    </svg>
  );
};

 const EditSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-pencil-square"
      viewBox="0 0 16 16"
    >
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
      <path
        fillRule="evenodd"
        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
      />
    </svg>
  );
};

const LoadingIndicator = () => {
  return (
    <RotatingLines
      visible={true}
      height="20"
      width="20"
      color="white"
      strokeColor="black"
      strokeWidth="5"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

function timeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
      return years === 1 ? "a yr ago" : `${years} yrs ago`;
  } else if (months > 0) {
      return months === 1 ? "a mon ago" : `${months} mons ago`;
  } else if (days > 0) {
      return days === 1 ? "a day ago" : `${days} days ago`;
  } else if (hours > 0) {
      return hours === 1 ? "an hr ago" : `${hours} hrs ago`;
  } else if (minutes > 0) {
      return minutes === 1 ? "a min ago" : `${minutes} mins ago`;
  } else {
      return seconds < 10 ? "just now" : `${seconds} secs ago`;
  }
}