import React, { useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { Switch, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { db, storage } from "../../config/firebaseconfig";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { v1 as uuid } from "uuid";

const label = { inputProps: { "aria-label": "Switch demo" } };

const EditProduct = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [inStock, setInStock] = useState(false);

  const defaultValues = {
    productName: "",
    brandName: "",
    model: "",
    price: "",
    serial_no: "",
  };

  const { handleSubmit, register, reset } = useForm({ defaultValues });

  const uploadFile = async () => {
    if (!file) return;

    let fileName = uuid();

    const storageRef = ref(storage, fileName);

    await uploadBytesResumable(storageRef, file);

    const filePath = `/${fileName}`;

    const fileRef = ref(storage, filePath);

    return await getDownloadURL(fileRef);
  };

  const handleOnSubmit = async (e) => {
    setLoading(true);

    try {
      if (!file) {
        throw new Error("Product image is required.");
      }
      let url = await uploadFile();

      if (!id) {
        await addDoc(collection(db, "storeData"), {
          ...e,
          inStock,
          imgUrl: url,
          timestamp: serverTimestamp(),
        });
      } else {
        await updateDoc(doc(db, "storeData", id), {
          ...e,
          inStock,
          imgUrl: url,
          timestamp: serverTimestamp(),
        });
      }

      reset();
      setFile(null);
      setLoading(false);
      window.history.go(-1);
    } catch (error) {
      setLoading(false);
      alert(error?.message);
    }
  };

  return (
    <DashboardContainer title={"BAC Store Details"}>
      <div className="mt-5">
        <form className="w-100" onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="bac-add-product-grid">
            <div className="">
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Product Name"
                required
                variant="outlined"
                defaultValue={defaultValues.productName}
                {...register("productName", { required: true })}
              />
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                required
                label="Brand Name"
                variant="outlined"
                defaultValue={defaultValues.brandName}
                {...register("brandName", { required: true })}
              />
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                required
                label="Serial Number"
                variant="outlined"
                defaultValue={defaultValues.serial_no}
                {...register("serial_no", { required: true })}
              />

              <label
                htmlFor="instock"
                className="d-flex flex-row gap-2 align-items-center"
              >
                <span>In Stock</span>
                <Switch
                  id="instock"
                  title="In Stock"
                  checked={inStock}
                  onChange={() => {
                    setInStock(!inStock);
                  }}
                  {...label}
                />
              </label>
            </div>
            <div className="">
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Model"
                defaultValue={defaultValues.model}
                variant="outlined"
                {...register("model", { required: true })}
              />
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                required
                label="Price"
                defaultValue={defaultValues.price}
                variant="outlined"
                {...register("price", { required: true })}
              />
            </div>
          </div>

          <div className="d-flex flex-wrap flex-row align-items-center gap-4 mt-5">
            <label
              htmlFor="product-image"
              className="d-flex flex-row align-items-center gap-3"
            >
              <input
                id="product-image"
                type="file"
                required
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </label>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="mt-4 align-self-start px-4 dash-button dash-button-rounded bg-steelblue"
          >
            {loading ? (
              <RotatingLines
                visible={true}
                height="20"
                width="20"
                color="white"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperClass=""
              />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </DashboardContainer>
  );
};

export default EditProduct;
