import React, { useEffect } from "react";
import "./annual_maintenace.css";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import PageDescription from "../Exp_inner_Components/PageDescription";
import Footer from "../AboutSec/Footer";
import Section2 from "./Section2";
import WhyChooseUs4Grid from "../Exp_inner_Components/WhyChooseUs4Grid";

let cards1 = [
  {
    title: "Expertise",
    paragraph: `Our team consists of highly trained technicians with extensive experience in servicing a wide range of HVACR chillers and equipment. From routine inspections to complex repairs.`,
  },
  {
    title: "Proactive Approach ",
    paragraph: `We believe in proactive maintenance to identify and address potential issues before they escalate into costly problems. Through regular inspections and preventive measures.`,
  },
  {
    title: "Customized Solutions ",
    paragraph: `We understand that every facility has unique requirements and challenges. That's why we offer customized maintenance plans tailored to suit your specific needs, budget, and schedule. `,
  },
  {
    title: "Comprehensive Services ",
    paragraph: `Our annual maintenance packages encompass a wide range of services, including but not limited to:
    Inspections: Thorough examination of all components to identify wear. 
    `,
  },
];

const AnnualMaintenance = () => {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavigateBack />

      <section className="annual_maintenace_main mb-5">
        <div className="container-fluid operat-main d-flex justify-content-center alig-items-center operat-innter">
          <div className="row ">
            <div className="col-12 d-flex justify-content-center alig-items-center operat-innter ">
              <div className="d-flex justify-content-center alig-items-center flex-column operate">
                <h1 className="text-white text-center">Annual Maintenance</h1>
                <p className="text-white col-lg-8 mx-auto text-center">
                  We provide professional Industrial process Annual Maintenance
                  as per specific requirement of the process machinery.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PageDescription
        headingStyles={" col-lg-9"}
        title={
          "Welcome to BAC Annual Maintenance Services for HVACR Chillers and Equipment"
        }
        description={
          "At BAC, we understand the critical role HVACR chillers and equipment play in maintaining optimal comfort and productivity within your facility. Our comprehensive annual maintenance services are tailored to ensure the peak performance and longevity of your HVACR systems, safeguarding your investment and providing peace of mind."
        }
      />

      <WhyChooseUs4Grid
        title={"Why Choose Us for Annual Maintenance?"}
        cards={cards1}
      />
      <Section2 />
      <Footer />
    </>
  );
};

export default AnnualMaintenance;
