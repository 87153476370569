import React, { Suspense, useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import "../components/css/Home.css";
import HomeVideo from "./HomeImages/bacr-2.mp4";
import HomeShape1 from "./HomeImages/home-shape-1.webp";

import HomeShape2 from "./HomeImages/home-shape-2.png";
import MachinesCarousel from "./HomeComponents/MachinesCarousel";
import { Link } from "react-router-dom";
import "react-modal-video/css/modal-video.min.css";
import ModalVideo from "react-modal-video";

function Home() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ModalVideo
        channel="youtube"
        youtube={{ mute: 0, autoplay: true }}
        isOpen={open}
        videoId="as_sm1gp9hs"
        onClose={() => setOpen(false)}
      />

      <div className="_hero_main_container">
        <img alt="" src={HomeShape1} className="_hero_img_top" />
        <img alt="" src={HomeShape2} className="_hero_img_bottom" />
        <div className="_hero_main container">
          <div className="_hero_main_sec_1 ">
            <p className="text-start  weleocnP">
              Professional and Quality HVACR Service!
            </p>
            <h1 className="_hero_main_heading">
              <Typewriter
                words={[
                  "ACROSS THE NATION, BEYOND THE SEA,  HVACR SOLUTIONS FOR ALL!",
                ]}
                loop={1}
                cursor
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </h1>
            <p className="text-white mt-1">
              At Brothers Air Conditioning, we are your trusted HVACR solution
              provider. Serving clients in Pakistan and around the globe, our
              mission is to deliver top-notch HVACR services that ensure your
              comfort, improve indoor air quality, and enhance energy
              efficiency. Whether you need a new installation, routine
              maintenance, or emergency repairs, our team of experts is here to
              provide reliable, high-quality solutions tailored to your needs.
              Explore our wide range of services and discover how we can keep
              your home or business comfortable all year round, no matter where
              you are.
            </p>

            <div className="video-play-btn-container">
              <Link to={"/BAC_Store"} className="_hero_stocks_btn rounded">
                <span>Avaliable Stock</span>
                <ArrowRight />
              </Link>
              <svg
                onClick={() => {
                  setOpen(!open);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                fill="#022837"
                className="bi bi-play-circle video-play-button"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445" />
              </svg>
            </div>

            <img alt="" src={HomeShape1} className="_hero_img_top_sm" />
          </div>
          <div className="_hero_main_sec_2">
            <video
              controls={false}
              controlsList="nodownload"
              playsInline
              loop
              muted
              autoPlay
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "transparent",
              }}
            >
              <source src={HomeVideo} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="_hero_main_sec_2_sm">
          <video
            controls={false}
            controlsList="nodownload"
            playsInline
            loop
            muted
            autoPlay
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: "transparent",
            }}
          >
            <source src={HomeVideo} type="video/mp4" />
          </video>
        </div>

        <Suspense>
          <MachinesCarousel parentClass={"_hero_carousel_container"} />
        </Suspense>
      </div>
    </>
  );
}

export default Home;

const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      className="bi bi-arrow-right"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
      />
    </svg>
  );
};
