import { useEffect, useState } from "react";
import "./index.css";
import Cookies from "js-cookie";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import LoginImage from "../Images/login-img.png";
import { api } from "../Actions/Api_Requests";

export default function Login() {
  const [IsLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let Navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get("Token");
    if (token) {
      Navigate("/dashboard");
    }
  }, []);

  let handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  let handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  let submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!email.trim().length || !password.trim().length) {
        throw new Error("Please fill all the fields.");
      }

      let res = await axios.post(`${api}/auth/login`, {
        email,
        password,
      });
      Cookies.set("Token", res.data.token, {
        expires: 7,
      });

      setIsLoading(false);

      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      swal(error.message);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center align-items-center bg-steelblue login-page-container ">
        <div className="d-flex justify-content-center align-items-center container">
          <form
            className=""
            onSubmit={submit}
            style={{ width: "25rem", padding: "20px" }}
          >
            <div className="d-flex flex-row justify-content-center">
              <img alt="" src={LoginImage} />
            </div>

            <div className="mb-3">
              <div className="input-container py-2 px-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="white"
                  className="bi bi-person-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                </svg>
                <input
                  type="text"
                  className=""
                  id="exampleFormControlInput1"
                  placeholder="User Name"
                  onChange={handleEmailChange}
                />
              </div>
            </div>

            <div className="position-relative">
              <div className="input-container py-2 px-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  fill="white"
                  className="bi bi-key-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
                <input
                  type={"password"}
                  id="inputPassword5"
                  className=""
                  placeholder="Password"
                  aria-describedby="passwordHelpBlock"
                  onChange={handlePasswordChange}
                />
              </div>
            </div>

            <div className="mt-4 mb-2">
              <div className="d-flex align-items-center gap-2 justify-content-center">
                {IsLoading ? (
                  <div className="spinner-border text-primary" />
                ) : (
                  <button
                    type="submit"
                    onClick={submit}
                    className="login-btn w-100"
                  >
                    Login
                  </button>
                )}
              </div>
            </div>
            <NavLink to={"/forgetPassword"} className="nav-link bg-transparent text-decoration-none text-white text-end">
              Forget Password?
            </NavLink>
          </form>
        </div>
      </div>
    </>
  );
}
