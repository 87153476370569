import React, { useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { PutRequestTest } from "../Actions/Api_Requests";
import { useDispatch, useSelector } from "react-redux";
import { updatedParentCard } from "../../Redux/reducers/DataSlice";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { useParams } from "react-router-dom";
import { ImageSvg } from "../BrandsComponent/EditChildForm";

const label = { inputProps: { "aria-label": "Authorized" } };

const EditCardDetails = () => {
  const { id } = useParams();
  const { cards } = useSelector((state) => state.DataSlice);
  const [cardImg, setCardImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [currentBgImage, setCurrentBgImage] = useState(null);
  const [formErrors, setFormErrors] = useState({ image: null, bgImg: null });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);

  const defaultValues = {
    name: activeCard?.name,
    description: activeCard?.description,
    location: activeCard?.location,
    website_url: activeCard?.website_url,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const handleImageChange = (e) => {
    setCardImg(e.target.files[0]);
    setFormErrors({ ...formErrors, image: null });
  };

  const handleBgOnchange = (e) => {
    setBackgroundImage(e.target.files[0]);
  };

  const onSubmit = async (e) => {
    try {
      setLoading(true);

      if (!currentImage && !cardImg) {
        setFormErrors({ ...formErrors, image: "Image is required." });
        return;
      }

      if (!currentBgImage && !backgroundImage) {
        setFormErrors({
          ...formErrors,
          bgImg: "Background Image is required.",
        });
        return;
      }

      let payload = {
        ...e,
        authorized,
      };

      if (cardImg) {
        payload.logo = cardImg;
      }

      if (backgroundImage) {
        payload.bg_img = backgroundImage;
      }

      let res = await PutRequestTest(
        `/api/card/update/parent/${id}`,
        payload,
        "multi"
      );

      setLoading(false);
      reset();
      setAuthorized(false);
      dispatch(updatedParentCard({ card: res.data.card, cardId: id }));
      setCardImg(null);
      setBackgroundImage(null);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  useEffect(() => {
    if (id && cards) {
      let card = cards?.find((item) => item._id === id);
      setActiveCard(card);
      setCurrentImage(card?.image?.path);
      setAuthorized(card?.authorized);
      setCurrentBgImage(card?.bg_img?.path);
    } else {
      navigate("/dashboard");
    }
  }, []);

  return (
    <DashboardContainer title={"Edit Card Details"}>
      <div className="my-2">
        <div className="d-flex flex-row flex-wrap align-items-center gap-4">
          <label
            htmlFor="card-1-img"
            style={{
              width: "160px",
              overflow: "hidden",
              borderRadius: "5px",
              height: "120px",
              background: cardImg ? "white" : "gray",
            }}
          >
            <input
              onChange={handleImageChange}
              style={{ display: "none" }}
              type="file"
              name="card-1-img"
              required
              id="card-1-img"
            />
            {cardImg ? (
              <img
                alt=""
                src={URL.createObjectURL(cardImg)}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : currentImage ? (
              <img
                alt=""
                src={currentImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <>
                <ImageSvg />
                <span className="text-white">Brand Logo</span>
              </>
            )}
          </label>
        </div>
        {formErrors?.image && (
          <span style={{ fontSize: "12px" }} className="text-danger">
            {formErrors?.image}
          </span>
        )}

        <div className="d-flex flex-row flex-wrap align-items-center gap-4 mt-3">
          <label
            htmlFor="card-1-bg"
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              width: "50%",
              overflow: "hidden",
              borderRadius: "5px",
              height: "200px",
              background: backgroundImage ? "white" : "#ccc",
            }}
          >
            <input
              onChange={handleBgOnchange}
              style={{ display: "none" }}
              type="file"
              name="card-1-bg"
              required
              id="card-1-bg"
            />

            {backgroundImage ? (
              <img
                alt=""
                src={URL.createObjectURL(backgroundImage)}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : currentBgImage ? (
              <img
                alt=""
                src={currentBgImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <>
                <ImageSvg />
                <span className="text-white">Brand Background Image</span>
              </>
            )}
          </label>
        </div>

        <div className="mt-5">
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            {/* main card inputs  */}
            <div className="edit-employee-grid gap-4">
              <div className="gap-4">
                <TextField
                  {...register("name", { required: true })}
                  multiline
                  rows={1}
                  focused
                  id="outlined-basic"
                  fullWidth
                  label="Name"
                  defaultValue={defaultValues.name}
                  variant="outlined"
                />
                <TextField
                  {...register("location", { required: true })}
                  multiline
                  rows={1}
                  focused
                  id="outlined-basic"
                  fullWidth
                  label="Location"
                  defaultValue={defaultValues.location}
                  variant="outlined"
                />

                <TextField
                  {...register("website_url", { required: true })}
                  multiline
                  rows={1}
                  focused
                  id="outlined-basic"
                  fullWidth
                  label="Website Url"
                  type="url"
                  defaultValue={defaultValues.website_url}
                  variant="outlined"
                />

                <label
                  htmlFor="authorized"
                  className="d-flex flex-row gap-2 align-items-center"
                >
                  <span>Authorized</span>
                  <Switch
                    id="authorized"
                    title="Autherized"
                    checked={authorized}
                    onChange={() => {
                      setAuthorized(!authorized);
                    }}
                    {...label}
                  />
                </label>
              </div>
              <div className="">
                <TextField
                  {...register("description", {
                    required: true,
                    minLength: {
                      value: 120,
                      message: "Description should be min 100 characters.",
                    },
                  })}
                  focused
                  id="outlined-basic"
                  fullWidth
                  multiline
                  error={errors?.description?.message}
                  defaultValue={defaultValues.description}
                  rows={5}
                  label="Description"
                  variant="outlined"
                />
                {errors?.description?.message && (
                  <span style={{ fontSize: "12px" }} className="text-danger">
                    {errors?.description?.message}
                  </span>
                )}
              </div>
            </div>

            <button
              type="button"
              onClick={() => {
                navigate("/dashboard");
              }}
              className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue mx-2"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue"
            >
              {loading ? (
                <RotatingLines
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default EditCardDetails;
