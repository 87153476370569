import React from "react";
import "./card.css";

function Card({ company, product, ePrice, model, imgUrl, serial_no, inStock }) {
  return (
    <div className="rounded custom bac-store-grid-card">
      <div
        className="d-flex justify-content-center px-3 py-3  position-relative"
        style={{ height: "15rem", width: "100%" }}
      >
        <img
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
          src={imgUrl}
          alt="machine"
        />
      </div>

      <div className="p-3 d-flex flex-column w-100">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h5 className="">{product}</h5>
          {inStock ? (
            <span
              style={{ color: "green", fontSize: "14px", fontWeight: "500" }}
            >
              In Stock
            </span>
          ) : (
            <span
              style={{ color: "green", fontSize: "14px", fontWeight: "500" }}
            >
              On Order Only
            </span>
          )}
        </div>

        <div className="d-flex flex-row align-items-center justify-content-between">
          <span style={{ fontSize: "14px" }}>Brand</span>
          <h6 className="">{company}</h6>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <span style={{ fontSize: "14px" }}>Model Number</span>
          <h6 className="px-2 py-1 rounded text-black">{model}</h6>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <span style={{ fontSize: "14px" }}>Serial Number</span>
          <h6 className="px-2 py-1 rounded text-black">{serial_no}</h6>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <span style={{ fontSize: "14px" }}>Price</span>
          <h6 className="bg-steelblue px-2 py-1 rounded text-white">
            {ePrice}
          </h6>
        </div>
      </div>
    </div>
  );
}

export default Card;
