import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../AboutSec/Footer";
import Header from "./ReuseableComponents/Header";
import Img1 from "./ProjectImages/napa.png";
import Img2 from "./ProjectImages/johsoncontrols.jpeg";
import Img3 from "./ProjectImages/albaraka.jpeg";
import Img4 from "./ProjectImages/aseptic.png";
import Img5 from "./ProjectImages/tufail.png";
import Img6 from "./ProjectImages/pso.jpeg";
import Img7 from "./ProjectImages/regent.jpg";
import Img8 from "./ProjectImages/ugarit.png";
import Img9 from "./ProjectImages/union.png";
import Img10 from "./ProjectImages/zarghun.png";
import Img11 from "./ProjectImages/hashim.jpeg";
import ProjectDetailsModel from "./ReuseableComponents/ProjectDetailsModel";

let projects = [
  {
    img: Img2,
    name: "Johnson Controls",
    nodes: {
      client: "Johnson Controls",
      location: [
        "Kabul, Afghanistan Dammam, Saudi Arabia",
        "Kenya, Nairobi Karachi, Pakistan",
        "Nooriabad, Pakistan",
      ],
      project_name: "Different Projects",
      job_nature:
        "Different types of jobs including installation, commissioning, supply, controls and programming.",
      brand: [
        "York YMA 1050",
        "York YCUL 0331",
        "York DMS 260",
        "York DMS 340",
        "York DMS 480",
      ],
    },
  },

  {
    img: Img8,
    name: "Ugarit Pharma",
    nodes: {
      client: "Ugarit Pharma",
      location: ["Aleppo", "Syria"],
      project_name: "Aleppo, Syria Project",
      job_nature: "Commissioning only",
      brand: ["LSK Dual fuel direct-fired absorption chiller"],
    },
  },
  {
    img: Img9,
    name: "Union Paper Mills",
    nodes: {
      client: "M.A.H.Y. Khoory & Co. L.L.C",
      location: ["Dubai", "UAE"],
      project_name: "Union Paper Mills Project",
      job_nature: "Commissioning only",
      brand: ["Trane Absorption Chiller"],
    },
  },
  {
    img: Img10,
    name: "Zarghun Central Gas Processing Facility",
    nodes: {
      client: "Kokken",
      location: ["Zarghun, Baluchistan"],
      project_name: "Zarghun Processing Plant Project",
      job_nature: "Commissioning only",
      brand: ["Propane-based refrigeration plant"],
    },
  },
  {
    img: Img11,
    name: "Hashim Medical City Hospital",
    nodes: {
      client: "Hashim Medical City Hospital",
      location: ["Hyderabad, Pakistan"],
      project_name: "Hashim Medical Project",
      job_nature: "Supply and Commissioning with Warranty",
      brand: ["Carrier 30 HXC 120 (120 Tons)"],
    },
  },
];

function OverseasProjet() {
  const [overseas, Setoverseas] = useState(false);
  const navigate = useNavigate();
  const ToNation_Porject = () => {
    navigate("/Projects/NationWide");
  };

  useEffect(() => {
    Setoverseas(true);
  }, []);
  const To_oversease_pROJECT = () => {
    navigate("/Projects/OverseasePorject");
  };
  const To_Client_projects = () => {
    navigate("/Projects/ClientProject");
  };
  const toBack = () => {
    navigate("/Projects");
  };
  const toKabul = () => {
    navigate("/Projects/Oversies/Kabul");
  };
  const toUgrait = () => {
    navigate("/Projects/Oversies/Ugarit");
  };
  const toUnion = () => {
    navigate("/Projects/Oversies/UnionPaper");
  };
  const toZargun = () => {
    navigate("/Projects/Oversies/Zarghun");
  };
  return (
    <>
      <Navbar />
      <Header />
      {/* =============================== */}
      <div className="container proj-conatiner ">
        <div className="row">
          <div className="col-12">
            <div className="btn-main-div">
              <div className=" ">
                <button
                  className="btn-gal nation "
                  style={{ backgroundColor: "white", color: "black" }}
                  onClick={toBack}
                >
                  ALL Projects
                </button>
                <button className={`btn-gal `} onClick={ToNation_Porject}>
                  Nation Wide Projects
                </button>
                <button
                  className={`btn-gal ${overseas === true ? "active1" : ""}`}
                  onClick={To_oversease_pROJECT}
                >
                  Overseas Projects
                </button>
                <button className="btn-gal " onClick={To_Client_projects}>
                  Clients
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <h1 className='text-center'>OVERSEASE PROJCETS</h1> */}
      <div className="container  my-5 ">
        <div className="row  my-5">
          {projects.map((item, index) => (
            <ProjectDetailsModel item={item} key={index} id={index} />
          ))}
          {/* <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-content "
            onClick={toUgrait}
          >
            <div
              className="bg-dark galler1 over1 position-relative "
              data-aos="fade-right"
            >
              <div className="proj-hover-div   d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center w-75 ">
                  Ugarit Pharmaceutical Co Syria
                </h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-content"
            onClick={toUnion}
          >
            <div
              className="bg-dark galler3 gal3 over3 gal-nat-3 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Union Paper Mills</h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-content"
            data-aos="flip-left"
            onClick={toKabul}
          >
            <div
              className="bg-dark galler3 over5  gal2  position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Johnson Controls</h5>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-content"
            onClick={toZargun}
          >
            <div
              className="bg-dark galler3 gal-6 over6 gal-nat-6 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">
                  Zarghun Central Gas Processing Facility
                </h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
          >
            <div
              className="bg-dark galler3 gal-7  hashi gal-nat-10 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column">
                <h5 className="text-white text-center ">Hasim Medical</h5>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OverseasProjet;
