import React, { useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import Plus from "../Images/+.png";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db, storage } from "../../config/firebaseconfig";
import { RotatingLines } from "react-loader-spinner";
import { deleteObject, ref } from "firebase/storage";

const BacStore = () => {
  const navigte = useNavigate();

  const handleAddProduct = () => {
    navigte("/dashboard/add-product");
  };

  const [delId, setDelId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "storeData"));
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({ id: doc.id, ...doc.data() });
      });
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error?.message);
    }
  };

  useEffect(() => {
    !data?.length && fetchData();
  }, []);

  const handleDeleteProduct = async (id) => {
    try {
      setDelId(id);
      setIsDeleting(true);

      let docRef = doc(db, "storeData", id);
      await deleteDoc(docRef);

      let card = data.find((item) => item.id === id);

      const imageUrl = card.imgUrl;
      const filename = imageUrl
        .substring(imageUrl.lastIndexOf("/") + 1)
        .split("?")[0];

      const storageRef = ref(storage, filename);
      await deleteObject(storageRef);

      setDelId(null);
      setIsDeleting(false);
      let UpdatedData = data?.filter((item) => item.id !== id);
      setData(UpdatedData);
    } catch (error) {
      console.log(error);
      setIsDeleting(false);
      alert(error?.message);
    }
  };

  let getDate = (item)=>{
    let date = new Date(item?.timestamp?.seconds * 1000 + item?.timestamp?.nanoseconds / 1000000 );
    let a = date?.getDate();
    let b = date?.getMonth()+1;
    let c = date?.getFullYear();
    return `${a}/${b}/${c}`
  }

  return (
    <DashboardContainer title={"BAC Store"}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="employees-container my-4">
          {data?.map((item, index) => (
            <div className="store-product-card rounded" key={index}>
              <button
                onClick={() => {
                  handleDeleteProduct(item?.id);
                }}
                className="store-product-del-btn"
              >
                {isDeleting && delId === item?.id ? (
                  <RotatingLines
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    strokeColor="black"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperClass=""
                  />
                ) : (
                  <DeleteSvg />
                )}
              </button>
              <div className="store-product-card-img-container">
                <img src={item?.imgUrl} className="" alt="..." />
              </div>

              <div className="p-3 d-flex flex-column w-100">
                <h5 className="">{item?.productName}</h5>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <span>Price</span>
                  <h5 className="">{item?.price}</h5>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <span>Brand</span>
                  <h5 className="">{item?.brandName}</h5>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <span>Model</span>
                  <h5 className="">{item?.model}</h5>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <span>Serial Number</span>
                  <h5 className="">{item?.serial_no}</h5>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <span style={{fontSize:"13px"}}>Created At</span>
                  <span className="" style={{fontSize:"13px"}}>{getDate(item)}</span>
                </div>
              </div>
            </div>
          ))}

          <div
            className="shadow store-product-add rounded bg-steelblue"
            onClick={handleAddProduct}
          >
            <img src={Plus} class="card-img-top" alt="..." />
            <div class="pt-3">
              <h2 className="text-center text-white">BAC Store</h2>
            </div>
          </div>
        </div>
      )}
    </DashboardContainer>
  );
};

const LoadingIndicator = () => {
  return (
    <div
      style={{ height: "60vh" }}
      className="w-100 d-flex align-items-center justify-content-center"
    >
      <RotatingLines
        visible={true}
        height="40"
        width="40"
        color="white"
        strokeColor="black"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

const DeleteSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-trash3"
      viewBox="0 0 16 16"
    >
      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
    </svg>
  );
};

export default BacStore;
